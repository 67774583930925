.ant-layout {
    background: transparent;
}

.ant-layout-sider {
    background-color: $menu-dark-bg;
}

#gradient-canvas {
  width:100%;
  height:100%;
  position: absolute;
  --gradient-color-1: #ef008f;
  --gradient-color-2: #6ec3f4;
  --gradient-color-3: #7038ff;
  --gradient-color-4: #ffba27;
}
